<template>
	<div
		class="row no-gutters">
		<div
			:class="stackedLayout ? 'col-6' : 'col-9'"
			class="d-flex p-0 h-100 align-items-center">
			<span
				:class="stackedLayout ? '' : 'ml-4'"
				class="text-medium">
				{{ translate(configuration) }}
				<template v-if="backorderInfo && backorderInfo.will_backorder">
					<i
						v-b-tooltip
						:title="translate('will_be_backordered')"
						class="fas fa-exclamation-triangle text-warning text-small" />
				</template>
				<br>
				<span>
					<b>{{ price }}</b>
					<template v-if="exchange">
						&nbsp;
						<span class="text-small exchange-text">{{ exchangePrice }}</span>
					</template>
					<template v-if="bvs > 0">
						&nbsp;-&nbsp;
						<span class="text-medium"> {{ bvs }} BVs</span>
					</template>
				</span>
			</span>
		</div>
		<div
			:class="stackedLayout ? 'col-6' : 'col-3'"
			class="d-flex w-100 align-items-center">
			<quantity-select
				:init-qty="initQty"
				:select-limit="10"
				:max="99"
				include-zero
				:container-class="`w-100 ${stackedLayout ? 'text-right' : ''}`"
				sub-container-class="justify-content-end w-100"
				@change="$emit('change', $event)" />
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';

export default {
	name: 'ProductConfiguration',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		configuration: {
			type: String,
			required: true,
		},
		price: {
			type: String,
			required: true,
		},
		exchange: {
			type: Boolean,
			default: false,
		},
		exchangePrice: {
			type: String,
			default: '',
		},
		initQty: {
			type: Number,
			required: true,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		backorderInfo: {
			type: Object,
			default: null,
		},
		bvs: {
			type: Number,
			default: 0,
		},
	},
};
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="autoshipModal"
		hide-footer
		no-close-on-esc
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="cancel">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ title }}
			</h5>
		</template>
		<div
			v-if="!loading"
			class="row no-gutters justify-content-center">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div class="mb-2">
					<b-alert
						:show="showBackorderInfo"
						variant="warning"
						class="mb-2">
						<div v-html="translate('backorder_info')" />
					</b-alert>
					<b-alert
						v-if="!!description"
						variant="info"
						class="m-0"
						show
						v-html="description" />
				</div>
			</div>
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<product-list
					v-if="hasOneTypeOfProducts"
					:configurable-products="getConfigurableProducts"
					:simple-products="getSimpleProducts"
					:selected-products="selectedProducts" />
				<template v-else>
					<b-tabs v-model="tabIndex">
						<b-tab @click="handleTabChange">
							<template #title>
								<a class="list-group-item btn text-left text-gray-dark">
									{{ translate('otg_products') }}
								</a>
							</template>
							<product-list
								:configurable-products="configurableProducts"
								:simple-products="simpleProducts"
								:selected-products="selectedProducts" />
						</b-tab>
						<b-tab @click="handleTabChange">
							<template #title>
								<a class="list-group-item btn text-left text-gray-dark">
									{{ translate('nfr_products') }}
								</a>
							</template>
							<product-list
								:configurable-products="nfrConfigurableProducts"
								:simple-products="nfrSimpleProducts"
								:selected-products="selectedProducts" />
						</b-tab>
					</b-tabs>
				</template>
			</div>
			<div class="col col-12 no-gutters justify-content-end text-right">
				<button
					type="button"
					:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
					aria-label="Close"
					class="btn w-100 btn-secondary btn-lg mt-3 mr-2"
					@click="cancel()">
					{{ translate('cancel') }}
				</button>
				<button
					:disabled="disableConfirm"
					type="button"
					:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
					aria-label="Close"
					class="btn w-100 btn-primary btn-lg mt-3"
					@click="confirm()">
					{{ translate('confirm_autoship') }}
				</button>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!products.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase } from '@/translations';
import ProductList from './ProductList';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';

export default {
	name: 'AddAutoshipModal',
	messages: [Products, Purchase],
	components: {
		IsLoading,
		ProductList,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		autoshipProducts: {
			type: Object,
			default: () => ({}),
		},
		loadingProducts: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedProducts: {},
			tabIndex: 0,
		};
	},
	computed: {
		loading() {
			return this.loadingProducts || !Object.keys(this.selectedProducts).length || !this.products.length;
		},
		simpleProducts() {
			return this.products
				.filter((product) => !product.attributes.is_nfr)
				.filter((product) => !product.attributes.has_configurations);
		},
		configurableProducts() {
			return this.products
				.filter((product) => !product.attributes.is_nfr)
				.filter((product) => product.attributes.has_configurations);
		},
		nfrSimpleProducts() {
			return this.products
				.filter((product) => product.attributes.is_nfr)
				.filter((product) => !product.attributes.has_configurations);
		},
		nfrConfigurableProducts() {
			return this.products
				.filter((product) => product.attributes.is_nfr)
				.filter((product) => product.attributes.has_configurations);
		},
		getConfigurableProducts() {
			if (this.nfrConfigurableProducts.length === 0) {
				return this.configurableProducts;
			}
			return this.nfrConfigurableProducts;
		},
		getSimpleProducts() {
			if (this.nfrSimpleProducts.length === 0) {
				return this.simpleProducts;
			}
			return this.nfrSimpleProducts;
		},
		hasOneTypeOfProducts() {
			const hasNfrProducts = this.nfrConfigurableProducts.length > 0 || this.nfrSimpleProducts.length > 0;
			const hasRegularProducts = this.configurableProducts.length > 0 || this.simpleProducts.length > 0;

			return hasNfrProducts !== hasRegularProducts || this.loadingProducts;
		},
		hasNfrProducts() {
			return this.nfrSimpleProducts.length > 0 || this.nfrConfigurableProducts.length > 0;
		},
		disableConfirm() {
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
		showBackorderInfo() {
			// eslint-disable-next-line camelcase
			return this.simpleProducts.some((item) => item.attributes.backorder_info?.will_backorder ?? false)
				|| this.configurableProducts.some((item) => {
					const children = !Array.isArray(item.attributes.children) ? Object.values(item.attributes.children) : item.attributes.children;
					// eslint-disable-next-line camelcase
					return children.some((child) => child.backorder_info?.will_backorder ?? false);
				});
		},
	},
	watch: {
		open(newVal) {
			if (newVal) {
				this.$refs.autoshipModal.show();
				this.selectedProducts = window.structuredClone(this.autoshipProducts);
			} else this.$refs.autoshipModal.hide();
		},
	},
	mounted() {
		if (this.open) this.$refs.autoshipModal.show();
	},
	methods: {
		confirm() {
			this.$emit('confirm', this.selectedProducts);
		},
		cancel() {
			this.$emit('cancel');
		},
		handleTabChange() {
			// Do nothing
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom {
		max-width: 880px !important;
		width: 880px !important;
	}
</style>

<template>
	<div>
		<b-card-group deck>
			<b-card style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
				<b-row>
					<b-col>
						<h4 class="mb-1">
							{{ translate('shipping_address') }}
						</h4>
					</b-col>
				</b-row>
				<hr class="mt-2 mb-4">
				<div class="row mb-2 no-gutters">
					<div
						v-if="!addressLoading"
						class="col">
						<div
							class="pb-1">
							<div
								class="row px-1">
								<div class="col-12">
									<address-radio-button
										class="mw-100 px-0"
										button-group-name="address-radio-shipping"
										:address-data="visibleAddresses"
										:address-loading="addressLoading"
										:form-import="form"
										:hide-new-address="addAddress"
										:display-add-new-address="false"
										:borderless="true"
										@add-address="addressFormState" />
									<div
										v-if="!addAddress"
										class="row">
										<div class="col-12 px-4">
											<hr class="mt-0">
										</div>
									</div>
									<div
										v-if="addAddress"
										class="pt-3 px-2">
										<address-country-form
											:address="ADDRESSES.shipping"
											:user-country="true"
											:form-import="form"
											:errors-import="addressErrors.errors"
											:prefix-errors="''"
											:display-submit-button="false"
											:display-cancel-button="false"
											:display-goback-button="false"
											:display-override-toggle="showOverrideValidation"
											:label-cancel-button="translate('see_address_list')" />
									</div>
								</div>
							</div>
							<div
								class="row px-2">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
									<button
										v-if="addAddress && hasAddressData"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('see_address_list') }}
									</button>
									<button
										v-else-if="!addAddress"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('use_another_address') }}
									</button>
								</div>
							</div>
							<div class="row px-2">
								<!-- NEW ADDRESS BUTTONS -->
								<div
									v-if="addAddress"
									:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
									class="col-12 col-md-auto">
									<div class="row no-gutters justify-content-end">
										<div
											v-if="hasAddressData"
											:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
											class="col order-2 order-md-1">
											<b-button
												:disabled="createAddressLoading"
												style="min-width: 150px"
												class="w-100"
												variant="light"
												size="lg"
												@click="addressFormState">
												{{ translate('cancel') }}
											</b-button>
										</div>
										<div
											:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
											class="col col-md-6 order-1 order-md-2">
											<b-button
												class="w-100"
												style="min-width: 200px"
												variant="primary"
												size="lg"
												@click="newAddress(form)">
												{{ translate('save_address_button') }}
											</b-button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-else
						class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
						<div class="col-12">
							<div class="h2">
								<i class="fa fa-fw fa-spinner fa-pulse" />
								<h4 class="mt-3">
									{{ translate('loading') }}
								</h4>
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</b-card-group>
	</div>
</template>
<script>
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import {
	UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import AddressBook from '@/util/AddressBook';

import Addresses from '@/views/BecomeAffiliate/mixins/Address';
import { ADDRESSES } from '@/settings/AddressBook';
import { Addresses as addressesMessages, Purchase } from '@/translations';

export default {
	name: 'NewDefaultAddress',
	messages: [addressesMessages, Purchase],
	components: {
		AddressRadioButton,
		AddressCountryForm,
	},
	mixins: [Addresses, WindowSizes],
	props: {
		errorsImport: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			createAddress: new AddressBook(),
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
				address_id: 0,
			},
			alert: new this.$Alert(),
			addAddress: false,
			showOverrideValidation: false,
			showMainCancel: true,
			visibleAddresses: [],
			ADDRESSES,
			addressType: 'shipping',
		};
	},
	computed: {
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
	},
	watch: {
		hasAddressData() {
			this.useFirstAddressIfNonAreSelected();
			this.showAddAddressIfNonAreAvailable();
		},
		addressLoading() {
			this.useFirstAddressIfNonAreSelected();
			this.showAddAddressIfNonAreAvailable();
		},
		addressData() {
			this.reloadAddress();
		},
		'form.address_id': {
			handler(value) {
				this.$emit('onUpdate', value);
			},
		},
	},
	created() {
		this.initializeValues();
	},
	methods: {
		initializeValues() {
			this.form.country_code = this.country;
		},
		showAddAddressIfNonAreAvailable() {
			if (!this.addressLoading && !this.addAddress && !this.hasAddressData) {
				this.addAddress = true;
			}
		},
		useFirstAddressIfNonAreSelected() {
			if (!this.addressLoading && this.hasAddressData && this.form.address_id === 0) {
				this.form.address_id = this.addressData[0].id;
			}
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.createAddress.saveAddress(this.$user.details().id, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.refreshAddressBook({ type: this.addressType }).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
		reloadAddress() {
			if (this.addressData.length > 0) {
				const dataCopy = this.addressData.map((address) => ({ ...address }));
				const sorted = dataCopy.sort((a, b) => a.attributes.is_gift_address - b.attributes.is_gift_address);
				this.visibleAddresses = sorted.filter((address) => !address.attributes.is_gift_address);
			}
		},
	},
};
</script>

<template>
	<div class="row mx-2 mt-1">
		<div class="col-12">
			<div
				v-for="(item, key) in configurableProducts"
				:key="item.attributes.sku"
				:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''"
				class="row">
				<div class="col">
					<configurable-product
						:code-name="item.attributes.code_name"
						:sku="item.attributes.sku"
						:config-name="'flavor'"
						:thumbnail="item.attributes.thumbnail"
						:stacked-layout="['xs', 'sm'].includes(windowWidth)">
						<div
							class="row"
							style="row-gap: 10px">
							<div
								v-for="(child, sku) in item.attributes.children"
								:key="child.code_name"
								class="col-12 col-md-6">
								<product-configuration
									:price="child.price.formatted_amount"
									:exchange="child.exchange"
									:exchange-price="child.exchange_price.formatted_amount"
									:bvs="Number(child.bvs)"
									:backorder-info="child.backorder_info"
									:configuration="child.code_name"
									:init-qty="selectedProducts[sku].value || 0"
									:stacked-layout="['xs', 'sm'].includes(windowWidth)"
									@change="$set(selectedProducts[sku], 'value', $event)" />
							</div>
						</div>
					</configurable-product>
				</div>
			</div>
		</div>
		<div class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-1">
			<div class="row mb-3">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'pl-0' : ''"
					class="col-12">
					<div class="row">
						<div
							v-for="item in simpleProducts"
							:key="item.attributes.sku"
							class="col-12 col-md-6">
							<simple-product
								:price="item.attributes.price.formatted_amount"
								:exchange="item.attributes.exchange"
								:exchange-price="item.attributes.exchange_price.formatted_amount"
								:backorder-info="item.attributes.backorder_info"
								:code-name="item.attributes.code_name"
								:bvs="Number(item.attributes.bvs)"
								:init-qty="selectedProducts[item.attributes.sku].value || 0"
								:thumbnail="item.attributes.thumbnail"
								:stacked-layout="['xs', 'sm'].includes(windowWidth)"
								@change="$set(selectedProducts[item.attributes.sku], 'value', $event)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SimpleProduct from '@/components/AddAutoshipModal/SimpleProduct.vue';
import ProductConfiguration from '@/components/AddAutoshipModal/ProductConfiguration.vue';
import ConfigurableProduct from '@/components/AddAutoshipModal/ConfigurableProduct.vue';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ProductList',
	components: {
		ConfigurableProduct,
		ProductConfiguration,
		SimpleProduct,
	},
	mixins: [WindowSizes],
	props: {
		configurableProducts: {
			type: Array,
			required: true,
		},
		simpleProducts: {
			type: Array,
			required: true,
		},
		selectedProducts: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style scoped>

</style>
